import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import { CardFlat } from "../components/preview";
import { Grid, Wrapper } from "../components/containers";

export default ({data}) => 
<Wrapper>
<SEO title="L Fried" description="Projects" />
<Grid id="content" className="slide-up" gridGap={[4]} gridAutoRows={[0, 1, 2, 3]} gridTemplateColumns={[0, 1, 2, 3]}  p={[1, 3, 3]} justifyContent="center" my={4}>
    {data.allProjects.edges.map(edge => { 
        let image = edge.node.image;
        let href = `${edge.node.title}`.toLowerCase().replace(/ /g,"_");
        return <CardFlat key={edge.node.id} src={image.fixed.src} srcSet={image.fixed.srcSet} title={edge.node.title} href={href}/>;
    })}
</Grid>
</Wrapper>;


export const pageQuery = graphql`
fragment ProjectPreview on ContentfulProject {
    id
    title
    category {
        title
    }
    image: preview {
        fixed(width: 500, height: 500, quality: 70) {
            ...GatsbyContentfulFixed
        }
    }
}

query ProjectTitles {
    allProjects: allContentfulProject(sort: {fields: date, order: DESC}) {
        edges {
            node {
                ...ProjectPreview
            }
        }
    }
    allContentfulSubcategory {
        edges {
            node {
                title
            }
        }
    }
}
`;
